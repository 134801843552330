import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {addNumber,isNumber} from "@core/utils/filter";

export default function salesreturnbounditemUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
   /* { key: 'returnbound_id', label: '退货单ID', sortable: true },
    { key: 'order_id', label: '销售单ID', sortable: true },
    { key: 'orderitem_id', label: '销售单产品明细ID', sortable: true },
    { key: 'outbound_id', label: '出库单ID', sortable: true },
    { key: 'outbounditem_id', label: '出库单明细ID', sortable: true },
    { key: 'product_id', label: '商品ID', sortable: true },*/
    { key: 'name', label: '名称'},
    { key: 'add_bulk', label: '添加散件'},
    { key: 'product_cost_tax', label: '销售价格', sortable: true },
    { key: 'cost_price', label: '销售成本', sortable: true },
    // { key: 'stock_id', label: 'ID', sortable: true },
    /*{ key: 'batch_no', label: '产品批次', sortable: true },
    { key: 'production_date', label: '生产日期', sortable: true },
    { key: 'expiry_date', label: '有效日期', sortable: true },*/
    { key: 'warehouse', label: '仓库'},
    { key: 'qty', label: '计划出库数量'},
    { key: 'trueqty', label: '实际出库数量'},
    { key: 'receiveqty', label: '对方实收数量'},
    // { key: 'is_return', label: '是否已退回仓库', sortable: true },
    { key: 'salesreturnqty', label: '预计退货数量'},
    { key: 'salesreturnqty_good', label: '实际退货数量(良品)  '},
    { key: 'salesreturnqty_bad', label: '实际退货数量(不良品)  '},
    // { key: 'add_time', label: '添加时间', sortable: true },
    // { key: 'modify_time', label: '修改时间', sortable: true },
    { key: 'salesreturnqtyOne', label: '已退货数量'}, //salesreturnqty_one
    { key: 'item_cost',headerTitle:'退货金额=(实际入库数量*销售价格):(预计退货数量*销售价格)', label: '小计'},
        //{ key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(1000000)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('returnbounditem_id')
  const isSortDirDesc = ref(true)
  const allReturnTotal = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('salesreturnbounditem/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        returnbound_id:config.returnbound_id
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        let total = 0;
        list.forEach(item=>{
          if(isNumber(item.is_return)>0){
            if(isNumber(item.service_id)>0&&(isNumber(item.salesreturnqty_good)+isNumber(item.salesreturnqty_bad)===0)){
              total = addNumber(total,item.product_cost_tax)
            }else{
              total = addNumber(total,(isNumber(item.salesreturnqty_good)+isNumber(item.salesreturnqty_bad))*item.product_cost_tax)
            }
          }else {
            total = addNumber(total,item.salesreturnqty*item.product_cost_tax )
          }
        })
        allReturnTotal.value = total
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '销售退货单明细列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    allReturnTotal,
    refetchData,

    // Extra Filters
  }
}
