<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <ValidationObserver ref="observerItemList">
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="dataList"
      >


        <!-- Columns -->
        <template #cell(name)="data">
          [#{{ data.item.id }}]{{ data.item.name }}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              variant="success"
              :id="`item-row-${data.item.id}`"
          />
          <b-tooltip
              :target="`item-row-${data.item.id}`"
              placement="top"
          >
            <p class="mb-0">
              69码：{{ data.item.ext.product.code || ""}}
            </p>
            <p class="mb-0">
              箱规：{{ data.item.ext.product.box_quantity || ""}}
            </p>
            <p class="mb-0">
              产品批次：{{ data.item.batch_no || ""}}
            </p>
            <p class="mb-0">
              生产日期：{{ toDate(data.item.production_date) || "" }}
            </p>
            <p class="mb-0">
              有效日期：{{ toDate(data.item.expiry_date) || "" }}
            </p>
          </b-tooltip>
        </template>
        <!-- Columns -->


        <template #cell(add_bulk)="data">
          <feather-icon
              icon="PlusSquareIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              @click="showAddBulkNew(data.item)"
              variant="success"
          />
        </template>

        <template #cell(salesreturnqty)="data">
          <b-form-input
              size="sm"
              type="number"
              @change="checkQty(data.item)"
              v-model="data.item.salesreturnqty"
          />


        </template>

        <template #cell(product_cost_tax)="data">
          <b-form-input
                  size="sm"
                  type="number"
                  @change="checkProductCostTax(data.item,data)"
                  v-model="data.item.product_cost_tax"
          />
          <!--{{ data.item.product_cost_tax }}-->
        </template>

        <template #cell(cost_price)="data">
          {{ data.item.cost_price }}
        </template>

        <template #cell(warehouse)="data">
          {{ data.item.ext.warehouse }}
        </template>

        <!-- Columns -->
        <template #cell(production_date)="data">
          {{ toDate(data.item.production_date) }}
        </template>

        <template #cell(salesreturnqtyOne)="data">
          {{ data.item.ext['salesreturnqtyOne'] }}
        </template>

        <template #cell(item_cost)="data">
          <div v-if="isNumber(data.item.is_return)>0">
            <div v-if="isNumber(data.item.service_id)>0&&(isNumber(data.item.salesreturnqty_good)+isNumber(data.item.salesreturnqty_bad))==0">{{isNumber(data.item.product_cost_tax)}}</div>
            <div v-else>{{ (isNumber(data.item.salesreturnqty_good)+isNumber(data.item.salesreturnqty_bad))*data.item.product_cost_tax }}</div>
          </div>
          <div v-else>
            {{ data.item.salesreturnqty*data.item.product_cost_tax }}
          </div>
        </template>


        <!-- Columns -->
        <template #cell(expiry_date)="data">
          {{ toDate(data.item.expiry_date) }}
        </template>

        <template #cell(actions)="data">
          <b-link @click="save(data.item)">
            <feather-icon
                icon="SaveIcon"
                size="16"
                class="align-middle text-body"
            />
          </b-link>
        </template>




      </b-table>
      </ValidationObserver>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->

        </b-row>
      </div>
    </b-card>

    <b-modal
        id="modal-addBulk-charge"
        ok-title="确认"
        cancel-title="取消"
        centered
        size="lg"
        @ok="addBulkSubmit"
        no-close-on-backdrop
        no-close-on-esc
        title="添加散件退货"
        ref="modal-addBulk-charge"
    >
      <b-row>
        <b-col md="12" style="margin-top: 3%">
          <div>
            <feather-icon
                icon="AlertCircleIcon"
                style="color:#e7e70d;width:24px;height:22px;margin-bottom:2px"
            />
            <span>   退货散件与原出库商品的标签相同才能操作，若无法选到散件，请联系公司采购添加商品标签。</span>
          </div>
        </b-col>
        <b-col md="6" style="margin-top: 3%">
          <b-card header="原出库商品">
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="名称:"
                  label-for="name"
                  label-size="sm"
                  class="b-inline-block mb-1"
              >
                <b-form-input
                    size="sm"
                    v-model="addBulkData.name"
                    readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="69码:"
                  label-for="code"
                  label-size="sm"
                  class="mb-1 "
              >
                <b-form-input
                    size="sm"
                    v-model="addBulkProduct.code"
                    readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="单箱标准:"
                  label-for="box_quantity"
                  label-size="sm"
                  class="mb-1 "
              >
                <b-form-input
                    size="sm"
                    v-model="addBulkProduct.box_quantity"
                    readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="出库数量:"
                  label-for="true_qty"
                  label-size="sm"
                  class="mb-1 "
              >
                <b-form-input
                    size="sm"
                    v-model="addBulkData.trueqty"
                    readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="销售价格:"
                  label-for="product_cost_tax"
                  label-size="sm"
                  class="mb-1 "
              >
                <b-form-input
                    size="sm"
                    v-model="addBulkData.product_cost_tax"
                    readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="成本价:"
                  label-for="cost_price"
                  label-size="sm"
                  class="mb-1 "
              >
                <b-form-input
                    size="sm"
                    v-model="addBulkData.cost_price"
                    readonly
                />
              </b-form-group>
            </b-col>
          </b-card>
        </b-col>

        <b-col md="6" style="margin-top: 3%">
          <b-card header="散件退货商品">
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="名称:"
                  label-for="cost_reference"
                  label-size="sm"
                  class="b-inline-block mb-1"
              >
                <v-select
                    size="5 "
                    id="brand_id"
                    :options="brandArray"
                    v-model="product_brandName"
                    @input="changeBrandId($event)"
                    placeholder="请选择商品"
                    class="select-size-sm"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="69码:"
                  label-for="product_code"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    size="sm"
                    v-model="changed_product_code"
                    readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="单箱标准:"
                  label-for="box_quantity"
                  label-size="sm"
                  class="mb-1 "
              >
                <b-form-input
                    size="sm"
                    v-model="changed_product_box_quantity"
                    readonly
                />

              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="退回数量:"
                  label-for="product_qty"
                  label-size="sm"
                  class="mb-1  required"
              >
                <b-form-input
                    size="sm"
                    v-model="changed_product_qty"
                    @change="changeQty($event)"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="退回售价:"
                  label-for="product_cost_tax"
                  label-size="sm"
                  class="mb-1 "
              >
                <b-form-input
                    size="sm"
                    v-model="changed_product_cost_tax"
                    @change="changePrice($event)"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="退回成本:"
                  label-for="cost_price"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    size="sm"
                    v-model="changed_product_cost_price"
                    readonly
                />

              </b-form-group>
            </b-col>
          </b-card>
        </b-col>

      </b-row>

    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty,
  isNumber,
  addNumber
} from '@core/utils/filter'
import salesreturnbounditemUseList from './salesreturnbounditemUseList'
import salesreturnbounditemStore from './salesreturnbounditemStore'
import {useToast} from "vue-toastification/composition";
import brandStore from "@/views/apps/brand/brandStore";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  methods: {
    calTotal(item) {
      console.log(item.returnbounditem_id)
      store.dispatch('salesreturnbounditem/getqty', {
        id: item.returnbounditem_id,
      })
          .then(res => {
            let max = res.data.data
            this.maxValue = `|max_value:${max}`
          })
    },
    async validateAll() {
      let count = 0
      let items = this.dataList
      for (let i = 0; i < items.length; i++) {
        if (await this.$refs[`observer${i}`].validate()) {
          count++
        }
      }
      return count === items.length
    },
    async getMax(index) {
      let item = this.dataList[index]
      // item.rule = undefined
      let qty = item.qty
      await store.dispatch('salesreturnbounditem/getqty', {
        id: item.returnbounditem_id,
      })
          .then(res => {
            let max = res.data.data
            item.rule = `required|nonnegativeInteger|max_value:${max}`
            this.dataList.splice(index, 1, item)
          })
    }
  },
  data() {
    return {
      dataList:[],
      addBulkData:[],
      addBulkProduct:[],
      brandArray: [],
      product_brandName: '',
      changed_product_code: '',
      changed_product_box_quantity:'',
      changed_product_cost_tax:'',
      changed_product_cost_price:'',
      changed_product_qty:'',
      changed_product_id:'',
      new_sales_order_return_item:[],
      productid :'',
      stockid :'',
      qty : '',
      bulkQty:'',
      canUseQty:'',
    }
  },
  props: {
    returnbound_id: {
      type: Number,
      default: 0
    },
  },
  setup(props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('salesreturnbounditem')) store.registerModule('salesreturnbounditem', salesreturnbounditemStore)
    if (!store.hasModule('brand')) store.registerModule('brand', brandStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesreturnbounditem')) store.unregisterModule('salesreturnbounditem')
      if (store.hasModule('brand')) store.unregisterModule('brand')
    })

    const save = function (params) {
      store.dispatch('salesreturnbounditem/updateItem', {
        item_id: params.returnbounditem_id,
        qty:params.salesreturnqty,
        productCostTax: params.product_cost_tax,
      })
      .then(res => {
        if(res.data.code === 0){
          toast.success("商品"+params.name+"保存成功")
          refetchData()
        }else{
          toast.error("商品"+params.name+"保存失败 "+res.data.data)
          refetchData()
        }
      })

    }
    const showAddBulkNew = function (params) {
      let salesReturnQtyOne = isNumber(params.ext.salesreturnqtyOne)  //已退数量
      let userQty = addNumber(params.trueqty,-salesReturnQtyOne) // 可用数量
      if(addNumber(userQty,-params.salesreturnqty)<=0){
        toast.error('退货数量不足，无法拆分，操作失败!')
      }else{
      if (params.ext['product'].box_quantity==1){
        toast.error('原出库商品单箱标准数量为1，操作失败!')
      }else{
        store.dispatch('salesreturnbounditem/view',{id:params.id}).then(res=>{
          if (res.data.code === 0) {
            this.addBulkData = res.data.data
          } else {
            toast.error(res.data.data)
          }
        })

        this.addBulkProduct = params.ext['product']
        this.brandArray = []
        this.productid = params.product_id
        this.stockid = params.stock_id
        this.qty = params.qty
        this.canUseQty = Math.floor((userQty)*this.addBulkProduct.box_quantity)
        let d = this.brandArray
        store.dispatch('brand/searchproductlabelrelation',{id:params.ext['product'].product_id}).then(res => {
          let a = res.data.data
          for (let i = 0; i < a.length; i++) {
            d.push({"label":a[i].name, "product_id": a[i].product_id,"code":a[i].code,"box_quantity":a[i].box_quantity})
          }
        })
        this.changed_product_code = ""
        this.product_brandName = ""
        this.changed_product_box_quantity = ""
        this.changed_product_cost_tax = ""
        this.changed_product_cost_price = ""
        this.changed_product_id = ""
        this.changed_product_qty = ""
        this.$refs['modal-addBulk-charge'].show()
      }
      }
    }


    const checkQty = function (params) {
      const cRegExp = /^[1-9]\d*$/
      if (!cRegExp.test(params.salesreturnqty)) {
        toast.error('售卖数量必须是正整数!')
        params.salesreturnqty = 0
        return false
      }

      if (params.salesreturnqty>params.trueqty-(params.ext.salesreturnqtyOne==undefined?0:params.ext.salesreturnqtyOne)){
        toast.error('预计退货数量必须小于实际出库数量!')
        params.salesreturnqty = 0
        return false
      }
      let list = this.dataList
      let total = 0;
      list.forEach(item=>{
        if(isNumber(item.is_return)>0){
          if(isNumber(item.service_id)>0&&(isNumber(item.salesreturnqty_good)+isNumber(item.salesreturnqty_bad)===0)){
            total = addNumber(total,item.product_cost_tax)
          }else{
            total = addNumber(total,(isNumber(item.salesreturnqty_good)+isNumber(item.salesreturnqty_bad))*item.product_cost_tax)
          }
        }else {
          total = addNumber(total,item.salesreturnqty*item.product_cost_tax )
        }
      })
      allReturnTotal.value = total
      this.$forceUpdate()
    }

    const changeQty = function (event) {
      const cRegExp = /^[1-9]\d*$/
      if (!cRegExp.test(event)) {
        toast.error('退回数量必须是正整数!')
        this.changed_product_qty = 0
      } else {
         if(this.changed_product_qty * this.changed_product_box_quantity >  this.canUseQty){
           this.changed_product_qty = 0
           toast.error("退回总数量不可大于可用总数量")
         }
      }
    }

    const changePrice = function (event) {
      const result = event>0
      if (!result) {
        toast.error('退回成本填写错误!')
        this.changed_product_cost_tax = 0
      }
    }
    const checkProductCostTax = function (params) {
      const result = params.product_cost_tax>0
      if (!result) {
        toast.error('销售价格填写错误!')
        params.product_cost_tax = 0
      }
      let list = this.dataList
      let total = 0;
      list.forEach(item=>{
        if(isNumber(item.is_return)>0){
          if(isNumber(item.service_id)>0&&(isNumber(item.salesreturnqty_good)+isNumber(item.salesreturnqty_bad)===0)){
            total = addNumber(total,item.product_cost_tax)
          }else{
            total = addNumber(total,(isNumber(item.salesreturnqty_good)+isNumber(item.salesreturnqty_bad))*item.product_cost_tax)
          }
        }else {
          total = addNumber(total,item.salesreturnqty*item.product_cost_tax )
        }
        //total = addNumber(total,(isNumber(item.salesreturnqty_good)+isNumber(item.salesreturnqty_bad))>0?(isNumber(item.salesreturnqty_good)+isNumber(item.salesreturnqty_bad))*item.product_cost_tax:(isNumber(item.salesreturnqty)==0?item.product_cost_tax:item.salesreturnqty*item.product_cost_tax) )
      })
      allReturnTotal.value = total
      this.$forceUpdate()
    }

    const changeBrandId = function (event) {
      if (event != null) {
        this.onSelectProducts(event);
      }
    }

    const onSelectProducts = function (data) {
      this.changed_product_code = ""
      this.product_brandName = ""
      this.changed_product_box_quantity = ""
      this.changed_product_cost_tax = ""
      this.changed_product_cost_price = ""
      this.changed_product_qty = ""
      this.changed_product_id = ""
      let selected = data
      this.changed_product_code = selected.code
      this.product_brandName = selected.label
      this.changed_product_id = selected.product_id
      if(this.product_brandName.length>10){
        this.product_brandName = selected.label.slice(0,10)+"..."
      }
      this.changed_product_cost_tax =  ((this.addBulkData.product_cost_tax/this.addBulkProduct.box_quantity) * selected.box_quantity).toFixed(2)
      this.changed_product_cost_price = ((this.addBulkData.cost_price / this.addBulkProduct.box_quantity) * selected.box_quantity).toFixed(2)
          //=销售价格*原单箱标准/退单箱标准
      this.changed_product_box_quantity = selected.box_quantity
      if(this.changed_product_box_quantity == this.addBulkProduct.box_quantity){
        this.changed_product_code = ""
        this.product_brandName = ""
        this.changed_product_box_quantity = ""
        this.changed_product_cost_tax = ""
        this.changed_product_cost_price = ""
        this.changed_product_id = ""
        this.changed_product_qty = ""
        toast.error('单箱标准数量相同，操作失败!')
      }
    }

    const addBulkSubmit = function (params) {
      if (this.changed_product_cost_price <= 0) {
        toast.error('退回售价不能小于0!')
        return false
      }
      if (this.changed_product_qty <= 0) {
        toast.error('退回数量不能小于0!')
        return false
      }
      if (this.product_brandName.length < 2) {
        toast.error('请选择商品!')
        return false
      }
      this.bulkQty = (this.changed_product_qty * this.changed_product_box_quantity) / this.addBulkProduct.box_quantity
      store.dispatch('salesreturnbounditem/saveBulkProduct', {
        ...this.addBulkData,
        productid: this.productid,
        changedproductid: this.changed_product_id,
        qty: this.qty,
        changedqty: this.changed_product_qty,
        stockid: this.stockid,
        oldsubqty: this.bulkQty,
        changedproductcostprice:this.changed_product_cost_price.trim(),
        changedproductcosttax:this.changed_product_cost_tax.trim(),

      }).then(res => {
        if (res.data.code === 0) {
          toast.success("添加成功!")
          props.returnbound_id = res.data.data.returnbound_id
          refetchData();
        } else {
          toast.error(res.data.data)
        }
      })
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      allReturnTotal,

      // UI
    } = salesreturnbounditemUseList({
      returnbound_id: props.returnbound_id
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toDate,
      toTime,
      save,
      checkQty,
      //showAddBulk,
      changeBrandId,
      onSelectProducts,
      changeQty,
      changePrice,
      addBulkSubmit,
      checkProductCostTax,
      showAddBulkNew,
      addNumber,isNumber,isEmpty,allReturnTotal

    }
  },
  watch: {
    allReturnTotal: {
      handler() {
        this.$emit("allReturnTotal", this.allReturnTotal)
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
